<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card :loading="isLoadingBase">
          <v-toolbar elevation="2">
            <v-toolbar-title>
              Editing: {{ baseSkeleton.name }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="returnToView">
              Return To View <v-icon class="ml-2"> mdi-keyboard-return </v-icon>
            </v-btn>
          </v-toolbar>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-stepper v-model="stepperStep">
          <v-stepper-header>
            <v-stepper-step :complete="stepperStep > 1" step="1">
              Basic Details
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :complete="(stepperStep > 2)" step="2">
              Linked Products
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :complete="(stepperStep > 3)" step="3">
              Price Rules
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step step="4" :complete="(stepperStep > 4)">
              Meta Data
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step step="5" :complete="(stepperStep > 5)">
              Pricing
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step step="6" :complete="(stepperStep > 6)">
              Affected Companies
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step step="7" :complete="(stepperStep > 7)">
              Finalised
            </v-stepper-step>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content step="1">
              <trev-product-basic-details :description.sync="editedSkeleton.description"
                :name.sync="editedSkeleton.name" :division.sync="editedSkeleton.division"
                :resourceType.sync="editedSkeleton.resourceType" :skuNumber.sync="editedSkeleton.skuNumber"
                :supplier.sync="editedSkeleton.supplier" :xeroTenantId.sync="editedSkeleton.defaultXeroTenantId"
                :xeroAccountCodeId.sync="editedSkeleton.defaultXeroAccountCodeId">
              </trev-product-basic-details>
              <!-- <v-text-field
                :loading="isLoadingBase"
                v-model="editedSkeleton.name"
              >
              </v-text-field>
              <v-textarea
                :loading="isLoadingBase"
                v-model="editedSkeleton.description"
              >
              </v-textarea> -->

              <v-btn color="primary" class="float-right" @click="stepperStep = 2">
                Continue
              </v-btn>
            </v-stepper-content>
            <v-stepper-content step="2">
              <trev-linked-product-skeleton-manage class="mb-3" v-if="hasLoadedLinkedSkeletons"
                :defaultSelectedProductSkeletons.sync="linkedProductSkeletons" :ProductSkeletonId="editedSkeleton.id">
              </trev-linked-product-skeleton-manage>
              <v-btn text @click="stepperStep--"> Previous </v-btn>
              <v-btn color="primary" class="float-right" @click="(stepperStep = 3)">
                Continue
              </v-btn>
            </v-stepper-content>
            <v-stepper-content step="3">
              <trev-product-price-rules :hideSwitch="true" :hasPriceRules="true" :priceRules="editedPriceRules">
              </trev-product-price-rules>
              <v-btn text @click="stepperStep--"> Previous </v-btn>
              <v-btn color="primary" class="float-right" @click="(stepperStep = 4)">
                Continue
              </v-btn>
            </v-stepper-content>
            <v-stepper-content step="4">
              <trev-product-metadata :tags="editedMetaData">
              </trev-product-metadata>
              <v-btn text @click="stepperStep--"> Previous </v-btn>
              <v-btn color="primary" class="float-right" @click="(stepperStep = 5)">
                Continue
              </v-btn>
            </v-stepper-content>
            <v-stepper-content step="5">
              <trev-product-price-settings :chargeFrequency.sync="editedSkeleton.chargeFrequency"
                :price.sync="editedSkeleton.price" :unitCharged.sync="editedSkeleton.unitCharged">
              </trev-product-price-settings>
              <v-btn text @click="stepperStep--"> Previous </v-btn>
              <v-btn color="primary" class="float-right" @click="(stepperStep = 6)">
                Continue
              </v-btn>
            </v-stepper-content>
            <v-stepper-content step="6">
              <trev-cool-table-base class="mb-4" title="Affected Companies" :showMenu="false" :hideTitle="true"
                :items="pagedAffectedCompanies" :headers="affectedCompaniesHeaders" :totalRecordCount="
                  affectedCompanies ? affectedCompanies.length : 0
                " :defaultPageSize="10" @pagination-updated="updatePagination">
                <template v-slot:item.products="{ item }">
                  <span v-for="product in item.products" :key="product.id">
                    <v-chip label class="mr-2">
                      {{ product.skuNumber }}
                    </v-chip>
                  </span>
                </template>
                <template v-slot:item.isComplete="{ item }">
                  <v-progress-circular indeterminate size="20" v-if="item.isUpdating && !item.isComplete">
                  </v-progress-circular>
                  <span v-else-if="!item.isComplete && !item.isUpdating">
                    Awaiting Save Changes
                  </span>
                  <v-icon class="success--text" v-else-if="item.isComplete">
                    mdi-check-circle
                  </v-icon>
                </template>
              </trev-cool-table-base>
              <v-btn text @click="stepperStep--"> Previous </v-btn>
              <v-btn v-if="isDoneUpdatingAll" color="primary" class="float-right"
                @click="(stepperStep = 7)">Continue</v-btn>
              <v-btn v-else color="success" class="float-right" @click="showAreYouSureSave = true">
                Save Changes Across System
                <v-icon class="ml-2">mdi-floppy</v-icon>
              </v-btn>
            </v-stepper-content>
            <v-stepper-content step="7">
              <v-row>
                <v-col>
                  <h4>
                    Successfully Updated
                    <span class="primary--text">{{
                      affectedProducts.length
                    }}</span>
                    Products over
                    <span class="success--text">{{
                      affectedCompanies.length
                    }}</span>
                    Companies
                  </h4>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-btn color="primary" :to="{
                    name: 'Product Skeleton View',
                    params: {
                      productSkeletonId: this.$route.params.productSkeletonId,
                    },
                  }">
                    Return To View
                    <v-icon class="ml-2"> mdi-keyboard-return </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-col>
    </v-row>
    <v-dialog v-model="showAreYouSureSave" max-width="60vh">
      <v-card>
        <v-toolbar>
          <v-toolbar-title>
            Save changes in {{ affectedProducts.length }} products across
            {{ affectedCompanies.length }} Companies?
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-row v-show="!isDoneUpdatingAll">
            <v-col>
              Processing <trev-number-ticker :number="tasksToComplete"></trev-number-ticker> Changes
            </v-col>
          </v-row>
          <v-row v-show="!isDoneUpdatingAll">
            <v-col>
              Completed <trev-number-ticker :number="tasksCompleted"></trev-number-ticker>
            </v-col>
          </v-row>
          <v-row v-show="isDoneUpdatingAll">
            <v-col>
              <v-alert color="success" class="my-0">
                Completed all {{ tasksCompleted }} changes
              </v-alert>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn text @click="showAreYouSureSave = false"> Cancel </v-btn>
          <v-btn color="primary" @click="saveChanges" v-show="!isDoneUpdatingAll" :loading="isAllUpdating">
            Yes, save<v-icon class="ml-2">mdi-floppy</v-icon>
          </v-btn>
          <v-btn color="success" v-show="isDoneUpdatingAll" @click="showAreYouSureSave = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showReturnToView" max-width="60vh">
      <v-card>
        <v-toolbar elevation="2">
          <v-toolbar-title> Cancel pending changes? </v-toolbar-title>
        </v-toolbar>
        <v-card-actions>
          <v-btn color="default" text @click="showReturnToView = false">
            No, continue editing
          </v-btn>

          <v-btn color="danger" class="white--text" :to="{
            name: 'Product Skeleton View',
            params: { productSkeletonId: $route.params.productSkeletonId },
          }">
            <v-icon> mdi-delete-outline </v-icon>
            Yes, cancel changes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import TrevProductBasicDetails from "../../../../../components/products/forms/trev-product-basic-details.vue";
import TrevProductMetadata from "../../../../../components/products/forms/trev-product-metadata.vue";
import TrevProductPriceRules from "../../../../../components/products/forms/trev-product-price-rules.vue";
import TrevProductPriceSettings from '../../../../../components/products/forms/trev-product-price-settings.vue';
import TrevLinkedProductSkeletonManage from '../../../../../components/products/productskeletons/trev-linked-product-skeleton-manage.vue';
import TrevCoolTableBase from "../../../../../components/tables/trev-cool-table-base.vue";
import TrevNumberTicker from '../../../../../components/ui/trev-number-ticker.vue';
import * as utils from "../../../../../utilities/GeneralUtilities";
import { convertProductSkeletonIntoProduct } from '../../../../../utilities/Object Utilities/Products';
export default {
  components: {
    TrevProductPriceRules,
    TrevProductMetadata,
    TrevCoolTableBase,
    TrevProductBasicDetails,
    TrevLinkedProductSkeletonManage,
    TrevProductPriceSettings,
    TrevNumberTicker,
  },
  computed: {
    pagedAffectedCompanies() {
      return utils.paginate(
        this.affectedCompanies,
        this.pageSize,
        this.pageNumber
      );
    },
  },
  async created() {
    this.getBaseSkeleton().then((skelly) => {
      this.baseSkeleton = skelly;
      this.editedSkeleton = JSON.parse(JSON.stringify(this.baseSkeleton));
      this.isLoadingBase = false;

      this.getAffectedCompanies();
    });

    this.getBaseSkeletonPriceRules().then((priceRules) => {
      this.basePriceRules = priceRules.payload;
      this.editedPriceRules = JSON.parse(JSON.stringify(this.basePriceRules));
      this.isLoadingBasePriceRules = false;
    });

    this.getBaseSkeletonMetaData().then((metaData) => {
      this.baseMetaData = metaData.payload;
      this.editedMetaData = JSON.parse(JSON.stringify(this.baseMetaData));
      this.isLoadingBaseMetaData = false;
    });

    this.getLinkedProductSkeletons().then(linkedResponse => {
      if (linkedResponse && linkedResponse.payload && linkedResponse.payload.length > 0) {
        this.linkedProductSkeletons = linkedResponse.payload;
        this.defaultLinkedProductSkeletons = JSON.parse(JSON.stringify(linkedResponse.payload));
      }

      this.hasLoadedLinkedSkeletons = true;
    });
  },
  methods: {
    async getLinkedProductSkeletons() {
      return await this.$courier.ProductSkeletons.withId(this.$route.params.productSkeletonId).LinkedProductSkeletons.getData(1, 9999, '');
    },
    async getBaseSkeleton() {
      return await this.$courier.ProductSkeletons.getById(
        this.$route.params.productSkeletonId
      );
    },
    async getBaseSkeletonPriceRules() {
      return await this.$courier.ProductSkeletons.withId(
        this.$route.params.productSkeletonId
      ).PriceRules.getData(1, 9999, "");
    },
    async getBaseSkeletonMetaData() {
      return await this.$courier.ProductSkeletons.withId(
        this.$route.params.productSkeletonId
      ).MetaData.getData(1, 9999, "");
    },
    async getAffectedCompanies() {
      var prodResponse = await this.$courier.Products.getData(
        1,
        99999,
        this.baseSkeleton.skuNumber,
        null,
        null,
        [{
          key: 'skuNumber',
          value: this.baseSkeleton.skuNumber
        }]
      );

      this.affectedProducts = prodResponse.payload;

      var prodTasks = [];

      for (const product of this.affectedProducts) {
        //get price ruels and meta dat a
        var innerTask = this.$courier.Products.withId(product.id)
          .PriceRules.getData(1, 9999, "")
          .then((priceRulesResp) => {
            product.priceRules = priceRulesResp.payload;
          });

        var innerTask2 = this.$courier.Products.withId(product.id)
          .MetaData.getData(1, 9999, "")
          .then((metaDataResp) => {
            product.metaData = metaDataResp.payload;
          });

        prodTasks.push(innerTask);
        prodTasks.push(innerTask2);
      }

      await Promise.all(prodTasks);

      var taskList = [];

      for (const product of this.affectedProducts) {
        var innerTask = this.$courier.Companies.getById(
          product.parentCompanyId
        ).then((company) => {
          company.isComplete = false;
          company.isUpdating = false;
          if (this.affectedCompanies.some((x) => x.id === company.id)) {
            //get the companies, add the product
            var foundCompany = this.affectedCompanies.find(
              (x) => x.id === company.id
            );

            if (!foundCompany.products) {
              foundCompany.products = [];
            } else {
              foundCompany.products.push(product);
            }
          } else {
            company.products = [];
            company.products.push(product);
            this.affectedCompanies.push(company);
          }
        });
      }
    },
    returnToView() {
      if (
        JSON.stringify(this.baseSkeleton) !== JSON.stringify(this.editSkeleton)
      ) {
        this.showReturnToView = true;
      } else {
        this.$router.push({
          name: "Product Skeleton View",
          params: { productSkeletonId: this.$route.params.productSkeletonId },
        });
      }
    },
    updatePagination(paginationObject) {
      this.pageNumber = paginationObject.pageNumber;
      this.pageSize = paginationObject.pageSize;
    },
    async saveChanges() {
      //grab the products and update them with new price rules
      this.isAllUpdating = true;
      //test it with thomas is cool
      var companiesToUpdate = JSON.parse(
        JSON.stringify(this.affectedCompanies)
      );

      var allCompanyTasks = [];

      //update the skeleton

      var tempProductSkeleton = JSON.parse(JSON.stringify(this.editedSkeleton));
      this.tasksToComplete++;
      await this.$courier.ProductSkeletons.updateById(
        this.editedSkeleton.id,
        tempProductSkeleton
      );
      this.tasksCompleted++;

      var childrenTasks = [];

      for (const oldPriceRule of this.basePriceRules) {
        this.tasksToComplete++;
        var op = this.$courier.PriceRules.removeById(oldPriceRule.id).then(() => {
          this.tasksCompleted++;
        });
        childrenTasks.push(op);
      }

      for (const oldMetaData of this.baseMetaData) {
        this.tasksToComplete++;
        var om = this.$courier.MetaData.removeById(oldMetaData.id).then(() => {
          this.tasksCompleted++;
        });
        childrenTasks.push(om);
      }

      for (const newPriceRule of this.editedPriceRules) {
        this.tasksToComplete++;
        var np = new Promise((resolve, reject) => {
          newPriceRule.parentProductSkeletonId = tempProductSkeleton.id;
          this.$courier.PriceRules.add(newPriceRule).then(() => {
            this.tasksCompleted++;
            resolve();
          }).catch(() => {
            this.tasksCompleted++;
            reject();
          })
        });
        childrenTasks.push(np);
      }

      for (const newMetaData of this.editedMetaData) {
        this.tasksToComplete++;
        var nm = new Promise((resolve, reject) => {
          delete newMetaData.id;
          newMetaData.parentProductSkeletonId = tempProductSkeleton.id;
          this.$courier.MetaData.add(newMetaData).then(() => {
            this.tasksCompleted++;
            resolve();
          }).catch(() => {
            this.tasksCompleted++;
            reject();
          });
        })
      }

      //do the linked product stuff for the product skeleton

      for (const linkedProduct of this.defaultLinkedProductSkeletons) {
        //remove the linked product
        this.tasksToComplete++;
        var olp = this.$courier.ProductSkeletons.withId(this.editedSkeleton.id).LinkedProductSkeletons.removeById(linkedProduct.id).then(() => {
          this.tasksCompleted++;
        });

        childrenTasks.push(olp);
      }

      for (const linkedProduct of this.linkedProductSkeletons) {
        //get the linked product and add it
        this.tasksToComplete++;
        var nlp = new Promise((resolve, reject) => {
          var p = this.$courier.ProductSkeletons.getById(linkedProduct).then(newProdSkel => {
            this.$courier.ProductSkeletons.withId(this.editedSkeleton.id).LinkedProductSkeletons.add(newProdSkel).then(() => {
              this.tasksCompleted++;
              resolve();
            }).catch(() => {
              reject();
            });
          }).catch(() => {
            reject();
          })
        });

        childrenTasks.push(nlp);
      }

      for (const company of companiesToUpdate) {
        var productTasks = [];
        company.isUpdating = true;

        for (const product of company.products) {
          //update basic
          this.tasksToComplete++;
          product.unitCharged = this.editedSkeleton.unitCharged;
          product.chargeFrequency = this.editedSkeleton.chargeFrequency;
          product.price = this.editedSkeleton.price;
          product.name = this.editedSkeleton.name;
          product.description = this.editedSkeleton.description;
          product.resourceType = this.editedSkeleton.resourceType;
          product.supplier = this.editedSkeleton.supplier;
          product.division = this.editedSkeleton.division;
          product.skuNumber = this.editedSkeleton.skuNumber;
          product.defaultXeroTenantId = this.editedSkeleton.defaultXeroTenantId;
          product.defaultXeroAccountCodeId = this.editedSkeleton.defaultXeroAccountCodeId;

          var prodTask = this.$courier.Products.updateById(product.id, product).then(() => {
            this.tasksCompleted++;
          });

          productTasks.push(prodTask);

          //update the price rules and meta

          //remove all the price rules and meta data and just re add them

          for (const priceRule of product.priceRules) {
            this.tasksToComplete++;
            var innerTask = this.$courier.PriceRules.removeById(
              priceRule.id
            ).then(() => {
              this.tasksCompleted++;
            });
            productTasks.push(innerTask);
          }

          for (const metaData of product.metaData) {
            this.tasksToComplete++;
            var innerTask = this.$courier.MetaData.removeById(metaData.id).then(
              () => {
                this.tasksCompleted++;
              }
            );
            productTasks.push(innerTask);
          }

          for (const newPriceRule of this.editedPriceRules) {
            this.tasksToComplete++;
            delete newPriceRule.id;
            delete newPriceRule.parentSkeletonProduct;
            delete newPriceRule.parentProduct;

            var tnPriceRule = JSON.parse(JSON.stringify(newPriceRule));
            tnPriceRule.parentProductId = product.id;

            var innerTask = this.$courier.PriceRules.add(newPriceRule).then(
              () => {
                this.tasksCompleted++;
              }
            );
            productTasks.push(innerTask);
          }

          for (const newMetaData of this.editedMetaData) {
            this.tasksToComplete++;
            delete newMetaData.id;
            delete newMetaData.parentProductId;
            delete newMetaData.parentProduct;
            delete newMetaData.parentProductSkeletonId;
            delete newMetaData.parentProductSkeleton;

            var tnMetaData = JSON.parse(JSON.stringify(newMetaData));
            tnMetaData.parentProductId = product.id;

            var innerTask = this.$courier.MetaData.add(tnMetaData).then(
              () => {
                this.tasksCompleted++;
              }
            );
            productTasks.push(innerTask);
          }

          //do the linked product skeletons for the product

          //remove all the linked products on the product
          this.tasksToComplete++;
          var removeLinkedProductsPromise = new Promise(async (resolve, reject) => {
            //get linked products
            var linkedProductsResponse = await this.$courier.Products.withId(product.id).LinkedProducts.getData(1, 999, '');

            if (linkedProductsResponse && linkedProductsResponse.payload && linkedProductsResponse.payload.length > 0) {
              for (const linkedProduct of linkedProductsResponse.payload) {
                this.tasksToComplete++;
                //delete the product? - no need to unlink from parent because it's being removed
                var rProduct = this.$courier.Products.removeById(linkedProduct.id).then(() => {
                  this.tasksCompleted++;
                });

                productTasks.push(rProduct);
              }
            }
          })


          //now need to convert the linked skeleton into a product and add it as a linked product
          for (const linkedProductSkeleton of this.linkedProductSkeletons) {
            this.tasksToComplete++;
            var addProdSkelTask = new Promise(async (resolve, reject) => {
              var generatedProduct = await convertProductSkeletonIntoProduct(this.$courier, linkedProductSkeleton);

              //add the product onot the company
              generatedProduct.parentCompanyId = product.parentCompanyId;
              generatedProduct.parentProductId = product.id;

              this.$courier.Products.add(generatedProduct).then(() => {
                this.tasksCompleted++;
              });
            });
          }

          allCompanyTasks.push({
            company,
            tasks: productTasks,
          });
        }
      }

      var allCompleteTasks = [];

      for (const companyTasks of allCompanyTasks) {
        var innerPromise = Promise.all(companyTasks.tasks).then(
          (companyTasksObj) => {
            this.affectedCompanies.filter(
              (x) => x.id === companyTasks.company.id
            )[0].isComplete = true;
            this.affectedCompanies.filter(
              (x) => x.id === companyTasks.company.id
            )[0].isUpdating = false;
          }
        );

        allCompleteTasks.push(innerPromise);
      }

      console.log('pre settled')

      await Promise.allSettled(childrenTasks);
      await Promise.allSettled(allCompleteTasks);

      //this.showAreYouSureSave = false;
      this.isAllUpdating = false;
      this.isDoneUpdatingAll = true;
    },
  },
  data() {
    return {
      tasksToComplete: 0,
      tasksCompleted: 0,
      hasLoadedLinkedSkeletons: false,
      stepperStep: 1,
      baseSkeleton: {},
      basePriceRules: [],
      baseMetaData: [],
      affectedCompanies: [],
      affectedProducts: [],
      editedSkeleton: {},
      editedPriceRules: [],
      editedMetaData: [],
      isLoadingBase: true,
      isLoadingBasePriceRules: true,
      isLoadingBaseMetaData: true,
      showReturnToView: false,
      isUpdatingAll: false,
      isAllUpdating: false,
      isDoneUpdatingAll: false,
      showAreYouSureSave: false,
      defaultLinkedProductSkeletons: [],
      linkedProductSkeletons: [],
      affectedCompaniesHeaders: [
        {
          text: "Company Name",
          value: "name",
        },
        {
          text: "Products",
          value: "products",
        },
        {
          text: "Is Updated",
          value: "isComplete",
        },
      ],
      pageNumber: 1,
      pageSize: 10,
      search: "",
    };
  },
};
</script>

<style>

</style>